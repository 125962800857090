import React, { memo } from "react";
import {formatDateByTimeZone, filterRefundsTransaction} from "src/utils";
import { valueFormat } from "src/helper/valueFormat";
import { convertPaymentMethod } from "src/utils/paymentUtils";

const collectTransactionsDto = (transactions) => {
  const filtered = filterRefundsTransaction(transactions);

  return filtered.map((item, { paymentInfo: p = {} }) => {
    const { emvTagData } = p;

    const tagData = emvTagData || null;
    const authCode = p.authcode  || '';

    let aid = '';
    let card_reader = '';

    if (tagData) {
      try {
        const emvData = JSON.parse(tagData);
        aid = emvData?.AID || '';
        card_reader = emvData?.['Entry method'] || '';
      } catch (err) {
        console.error('Failed to parse emvTagData:', err);
      }
    }

    const retRef = p?.retRef || p?.retref || '';
    return {
      changed:        item?.paymentInfo?.changed || p?.changed || item?.changed || null,
      amount:        item?.paymentInfo?.tendered || item?.tendered || p?.tendered || null,
      tender:        convertPaymentMethod(item?.tender?.method) || '',
      card_type:     item?.paymentInfo?.cardType || p?.cardType || '',
      account:       item?.paymentInfo?.lastFour || p?.lastFour ?
        `XXXX XXXX XXXX ${item?.paymentInfo?.lastFour || p?.lastFour || ''}` : null,
      capture:       item?.paymentInfo?.capture || p?.capture || '',
      pin:           item?.paymentInfo?.pin || p?.pin || '',
      authCode:      authCode || item?.paymentInfo?.authCode || '',
      retRef:        item?.paymentInfo?.retRef || retRef || '',
      terminalId:    item?.paymentInfo?.terminalId || p?.terminalId || '',
      transactionId: item?.paymentInfo?.transactionId || p?.transactionId || '',
      aid:           aid || item?.paymentInfo?.aid || '',
      card_reader:   card_reader || item?.paymentInfo?.card_reader || '',
      createdAt:     item?.createdAt || null
    };
  });
};

const RefundTransactions = ({ orderData }) => {
  const transactions = collectTransactionsDto(orderData?.metadata?.transactions);
  const timeZoneId = orderData?.metadata?.business?.timezone?.timeZoneId;

  return (
    <>
      {transactions?.map((transaction, index) => {
        return (
          <>
            <div key={index} className="payment-container">
              <div style={{ width: "100%" }}>

                <div style={{ marginBottom: '16px' }}>
                  <div className="payment-container-flex">
                    <p className="payment-title uppercase" style={{ fontWeight: 600, fontSize: 20 }}>
                      Refund:
                    </p>
                    <p className="payment-title" style={{ fontWeight: 600, fontSize: 20 }}>
                      {valueFormat(`($${Number(transaction?.amount || 0).toFixed(2)})`)}
                    </p>
                  </div>
                  {transaction?.createdAt && (
                    <div className="payment-container-flex" style={{ marginTop: '12px' }}>
                      <p className="payment-title uppercase">
                        Refund Date:
                      </p>
                      <p className="payment-title">
                        {formatDateByTimeZone(transaction?.createdAt, timeZoneId)}
                      </p>
                    </div>
                  )}
                  {orderData?.charges?.total && (
                    <div className="payment-container-flex">
                      <p className="payment-title uppercase">
                        Order Total:
                      </p>
                      <p className="payment-title">
                        {valueFormat(`$${Number(orderData?.charges?.total || 0).toFixed(2)}`)}
                      </p>
                    </div>
                  )}
                  {transaction?.amount && (
                    <div className="payment-container-flex">
                      <p className="payment-title uppercase">
                        Refund total:
                      </p>
                      <p className="payment-title">
                        {valueFormat(`($${Number(transaction?.amount || 0).toFixed(2)})`)}
                      </p>
                    </div>
                  )}
                </div>

                {transaction?.card_type && (
                  <div className="delivery-wrapper" style={{ marginTop: '12px' }}>
                    <p className="payment-title uppercase payment-wrapper-left">
                      Card Type:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.card_type}
                    </p>
                  </div>
                )}
                {transaction?.account && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      Account:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.account}
                    </p>
                  </div>
                )}
                {transaction?.capture && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      Capture:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.capture}
                    </p>
                  </div>
                )}

                {transaction?.pin && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      Pin:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.pin}
                    </p>
                  </div>
                )}
                {transaction?.authCode && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      Auth code:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.authCode}
                    </p>
                  </div>
                )}

                {transaction?.retRef && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      Auth ref:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.retRef}
                    </p>
                  </div>
                )}

                {transaction?.terminalId && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      Terminal Id:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.terminalId}
                    </p>
                  </div>
                )}

                {transaction?.transactionId && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      Trans #:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.transactionId}
                    </p>
                  </div>
                )}

                {transaction?.aid && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      aid:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.aid}
                    </p>
                  </div>
                )}

                {transaction?.card_reader && (
                  <div className="delivery-wrapper">
                    <p className="payment-title uppercase payment-wrapper-left">
                      card reader:
                    </p>
                    <p className="payment-title payment-wrapper-right uppercase">
                      {transaction?.card_reader}
                    </p>
                  </div>
                )}

              </div>
            </div>
            <hr className="line-header"/>
          </>
        )
      })}
    </>
  )
}
export default memo(RefundTransactions)