import React, { memo } from 'react';

const OrderNote = ({ instructions }) => {
  return (
    <>
      <div className="orderNoteBlock">
        <div>
          <strong className="note uppercase">Note:</strong>
        </div>
        <div style={{ marginLeft: '10px' }} className="instructions">
          {instructions?.split('\n')?.map((item, index) => (
            <i key={index} style={{ display: 'block' }}>
              {item}
            </i>
          ))}
        </div>
      </div>
      <hr className="line-header" />
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps?.instructions === nextProps?.instructions;
};

export default memo(OrderNote, areEqual);