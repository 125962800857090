import React, { memo, useMemo } from "react";
import { valueFormat } from "src/helper/valueFormat";
import { checkDiscount } from "src/helper/checkDiscount";
import {
  convertKitchenTip,
  convertTaxAndFee,
  convertTips,
} from "src/helper/tips";
import { MESSAGE_VARIABLES } from "src/constants";
import {ORDER_TYPE_KEYS, ORDER_TYPE_KEYS_DELIVERY} from "src/utils";

const OrderCharges = ({ orderData }) => {

  const checkTipSettings = useMemo(() => {
    const currentBusiness = orderData?.metadata?.business ? orderData?.metadata?.business : {};
    if (currentBusiness?.settings?.website?.tipDisplaySetting) {
      const { tipRestaurant, tipDriver } = currentBusiness?.settings?.website?.tipDisplaySetting;
      return {
        tipRestaurantEnabled: tipRestaurant?.showTipRestaurant || true,
        tipDriverEnabled: tipDriver?.showTipDriver || true
      };
    }
    return {
      tipRestaurantEnabled: true,
      tipDriverEnabled: true
    };
  },[orderData])


  return (
    <>
      <tr className="totals-price-size">
        <td className="uppercase">Subtotal:</td>
        <td className="totals-price-size">
          {valueFormat(`$${Number(orderData?.charges?.subTotal).toFixed(2)}`)}
        </td>
      </tr>
      {(orderData?.charges?.couponAmount > 0 &&
        orderData?.charges?.couponAmount > 0) ||
      (orderData?.charges?.discount > 0) ? (
        <tr className="totals-price-size">
          <td className="uppercase">
            Discount
            {orderData?.charges?.discountDetails?.couponTitle
              ? ` (${orderData.charges.discountDetails.couponTitle}):`
              : orderData?.charges?.discountDetails?.couponName
                ? ` (${orderData.charges.discountDetails.couponName}):`
                : orderData?.charges?.discountDetails?.discountCode
                  ? ` (${orderData.charges.discountDetails.discountCode}):`
                  : ':'}
          </td>
          <td>
            {valueFormat(
              `($${Number((orderData?.charges?.promotion?.couponAmount || 0) +
                (orderData?.charges?.discount || 0)).toFixed(
                2
              )})`
            )}
          </td>
        </tr>
      ) : <></>}
      {ORDER_TYPE_KEYS_DELIVERY.includes(orderData?.orderType) ? (
        <tr className="totals-price-size">
          <td className="uppercase">Delivery Fee:</td>
          <td>
            {valueFormat(
              `$${Number(orderData?.charges?.deliveryFee).toFixed(2)}`
            )}
          </td>
        </tr>
      ) : <></>}
      {(checkTipSettings.tipDriverEnabled || checkTipSettings.tipRestaurantEnabled) && (
        <>
          {orderData.type !== "delivery" && !checkTipSettings.tipRestaurantEnabled ? (
            <></>
          ) : (
            <tr className="totals-price-size">
              <td className="uppercase">Tips:</td>
              <td>{valueFormat(`$${Number(orderData?.charges?.tip || 0).toFixed(2)}`)}</td>
            </tr>
          )}
          {/*{(orderData.charges?.tipInfo?.kitchen ||*/}
          {/*  orderData.charges?.tipInfo?.kitchen == 0) && checkTipSettings.tipRestaurantEnabled ? (*/}
          {/*  <tr className="tip totals-price-size" style={{paddingLeft: '32px'}}>*/}
          {/*    <td className="uppercase ">Kitchen</td>*/}
          {/*    <td*/}
          {/*      className="totals-price-size"*/}
          {/*      style={{ paddingRight: '200px' }}*/}
          {/*    >*/}
          {/*      {convertKitchenTip(orderData.charges?.tipInfo?.kitchen)}*/}
          {/*    </td>*/}
          {/*  </tr>*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
          {/*{orderData.type === "delivery" && checkTipSettings.tipDriverEnabled ? (*/}
          {/*  <>*/}
          {/*    {orderData.charges?.tipInfo?.driver ||*/}
          {/*    orderData.charges?.tipInfo?.driver === 0 ? (*/}
          {/*      <tr className="tip totals-price-size" style={{paddingLeft: '32px'}}>*/}
          {/*        <td>Driver</td>*/}
          {/*        <td style={{paddingRight: '200px' }}>*/}
          {/*          {convertKitchenTip(orderData.charges?.tipInfo?.driver)}*/}
          {/*        </td>*/}
          {/*      </tr>*/}
          {/*    ) : (*/}
          {/*      ""*/}
          {/*    )}*/}
          {/*  </>*/}
          {/*) : (*/}
          {/*  ""*/}
          {/*)}*/}
        </>
      )}
      <tr className="totals-price-size uppercase">
        <td>Taxes and Fees:</td>
        <td>
          {convertTaxAndFee(
            orderData?.charges?.servicingFee,
            orderData?.charges?.taxAmount
          )}
        </td>
      </tr>
      <tr className="totals-price-size uppercase delivery-wrapper" style={{ paddingLeft: "32px", fontSize: '16px' }}>
        <td className="delivery-wrapper-left" style={{ fontSize: '16px' }}>Tax:</td>
        <td className="delivery-wrapper-right" style={{ fontSize: '16px' }}>
          {valueFormat(`$${Number(orderData?.charges?.taxAmount || 0).toFixed(2)}`)}
        </td>
      </tr>
      {orderData?.charges?.servicingFee?.processingFee ||
       orderData?.charges?.servicingFee?.marketplaceFee ||
       orderData?.charges?.servicingFee?.ordersCoFee ? (
        <tr className="totals-price-size uppercase delivery-wrapper" style={{ paddingLeft: "32px", fontSize: '16px' }}>
          <td className="delivery-wrapper-left" style={{ fontSize: '16px' }}>Processing Fee:</td>
          <td className="delivery-wrapper-right" style={{ fontSize: '16px' }}>{valueFormat(
            `$${Number(
              (orderData?.charges?.servicingFee?.processingFee || 0) +
              (orderData?.charges?.servicingFee?.marketplaceFee || 0) +
              (orderData?.charges?.servicingFee?.ordersCoFee || 0)
            ).toFixed(2)}`
          )}</td>
        </tr>
      ) : (
        ""
      )}
      <hr className="line-header" style={{ marginBottom: '12px' }}/>
      <tr className="totals-price-size uppercase">
        <td>Total:</td>
        <td>
          {valueFormat(`$${Number(orderData?.charges?.total || 0).toFixed(2)}`)}
        </td>
      </tr>
      {orderData?.charges?.rewardAmount && orderData?.charges?.rewardAmount > 0 ? (
        <tr className="totals-price-size uppercase">
          <td>{MESSAGE_VARIABLES.cashback}</td>
          <td>{checkDiscount(orderData?.charges?.rewardAmount || 0)}</td>
        </tr>
      ) : (
        <></>
      )}
      {orderData?.charges?.paidAmount ? (
        <tr className="totals-price-size uppercase">
          <td>Paid:</td>
          <td>
            {valueFormat(`$${Number(orderData?.charges?.paidAmount || 0).toFixed(2)}`)}
          </td>
        </tr>
      ) : (
        <></>
      )}
      <hr className="line-header" style={{ marginTop: '12px' }}/>
      <tr className="uppercase global-total">
        <td
          style={{
            color: (orderData?.charges?.balanceDue ?? 0) < 0 ? 'red': 'initial'
          }}
        > {(orderData?.charges?.balanceDue ?? 0) < 0
          ? 'Refund Due:'
          : 'Balance Due:'}</td>
        <td
          style={{
            color: (orderData?.charges?.balanceDue ?? 0) < 0 ? 'red': 'initial'
          }}
        >
          {valueFormat(`$${Number(Math.abs(orderData?.charges?.balanceDue)|| 0).toFixed(2)}`)}
        </td>
      </tr>
    </>
  );
};
export default memo(OrderCharges);
