import React, {memo} from 'react';


const OrderPickUpInfo = ({ customerInfo, refCode }) => {
  return (
    <>
      {customerInfo?.firstName && customerInfo?.phoneNumber ? (
        <p className="uppercase delivery-title">Pick Up Information:</p>
      ) : (<></>)}
      {customerInfo?.firstName && (
        <div className="delivery-wrapper">
          <p className="delivery-wrapper-left uppercase">Customer:</p>
          <p className="delivery-wrapper-right">{`${customerInfo?.firstName || ''} ${customerInfo?.lastName || ''}`}</p>
        </div>
      )}
      {customerInfo?.phoneNumber && (
        <div className="delivery-wrapper make-padding">
          <p className="delivery-wrapper-left uppercase">Phone:</p>
          <p className="delivery-wrapper-right">{`${customerInfo?.phoneNumber}` || ''}</p>
        </div>
      )}
      {refCode && (
        <div className="delivery-wrapper make-padding">
          <p className="delivery-wrapper-left uppercase">Reference number/code:</p>
          <p className="delivery-wrapper-right">{`${refCode}` || ''}</p>
        </div>
      )}
      <hr className="line-header" />
    </>
  )
}

export default memo(OrderPickUpInfo)