import React, {memo, useMemo} from 'react';

const DROP_OF_VERIFICATION = {
  leaveAtDoor: 'Leave it at my door.',
  meetAtDoor: 'Hand it to me.'
}

const OrderDeliveryInfo = ({ deliveryInfo, customerInfo, refCode }) => {

  const dropOffVerification = useMemo(() => {
    return deliveryInfo?.leaveAtDoor ?
      DROP_OF_VERIFICATION.leaveAtDoor :
      deliveryInfo?.meetAtDoor ? DROP_OF_VERIFICATION.meetAtDoor : ''
  }, [deliveryInfo?.leaveAtDoor, deliveryInfo?.meetAtDoor])

  const deliveryBy = useMemo(() => {
   if ((deliveryInfo?.delivery?.provider === 'self') || (deliveryInfo?.quote?.provider === 'self')) {
     return 'IN-HOUSE DELIVERY'
   }
   if ((deliveryInfo?.delivery?.provider === 'dispatch') || (deliveryInfo?.quote?.provider === 'dispatch')) {
     return 'AI DISPATCH'
   }
   if (deliveryInfo?.delivery?.provider || deliveryInfo?.quote?.provider) {
     return deliveryInfo?.delivery?.provider?.toUpperCase() || deliveryInfo?.quote?.provider?.toUpperCase();
   }
   return null
  },[deliveryInfo?.quote?.provider, deliveryInfo?.delivery?.provider])

  return (
    <>
      <p className="uppercase delivery-title">Delivery Information:</p>
      <div className="delivery-wrapper">
        <p className="delivery-wrapper-left uppercase">Customer:</p>
        <p className="delivery-wrapper-right">{`${customerInfo?.firstName || ''} ${customerInfo?.lastName || ''}`}</p>
      </div>
      <div className="delivery-wrapper make-padding">
        <p className="delivery-wrapper-left uppercase">Phone:</p>
        <p className="delivery-wrapper-right">{`${customerInfo?.phoneNumber}` || ''}</p>
      </div>
      {deliveryInfo?.addressInfo?.fullAddress && (
        <div className="delivery-wrapper make-padding">
          <p className="delivery-wrapper-left uppercase">Address:</p>
          <p className="delivery-wrapper-right">{`${deliveryInfo?.addressInfo?.fullAddress}` || ''}</p>
        </div>
      )}
      {deliveryBy && (
        <div className="delivery-wrapper make-padding">
          <p className="delivery-wrapper-left uppercase">DELIVERY BY:</p>
          <p className="delivery-wrapper-right">{`${deliveryBy}` || ''}</p>
        </div>
      )}
      {refCode && (
        <div className="delivery-wrapper make-padding">
          <p className="delivery-wrapper-left uppercase">Reference number/code:</p>
          <p className="delivery-wrapper-right">{`${refCode}` || ''}</p>
        </div>
      )}
      {(dropOffVerification || deliveryInfo?.deliveryInstructions) &&  (
        <div className="delivery-wrapper make-padding">
          <p className="delivery-wrapper-left uppercase">Delivery Instructions:</p>
          <p className="delivery-wrapper-right">
            {dropOffVerification && `${dropOffVerification}`}&nbsp;
            {deliveryInfo?.deliveryInstructions?.split('\n')?.map((item, index) => {
              if (!item) return null;
              return (
                <i key={index} style={{ display: 'block' }}>
                  {`"${item}"`}
                </i>
              );
            })}
          </p>
        </div>
      )}
      <hr className="line-header" />
    </>
  )
}

export default memo(OrderDeliveryInfo)