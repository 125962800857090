import React, { Fragment, useEffect, useState } from "react";
import "moment-timezone";
import "src/index.css";
import AuthService from "src/service/api";
import {ORDER_TYPE_KEYS} from "src/utils";
import { useNavigate } from "react-router-dom";
import OrderNote from "src/components/Header/OrderNote";
import OrderHeader from "src/components/Order/OrderHeader";
import OrderProduct from "src/components/Order/OrderProduct";
import OrderCharges from "src/components/Order/OrderCharges";
import { groupByCategory } from "src/helper/groupByCategory";
import OrderFooter from 'src/components/OrderFooter/OrderFooter';
import OrderTransactions from "src/components/Order/OrderTransactions";
import OrderPickUpInfo from "src/components/OrderPickUpInfo/OrderPickUpInfo";
import OrderDeliveryInfo from "src/components/OrderDeliveryInfo/OrderDeliveryInfo";
import RefundTransactions from "src/components/Order/RefundTransactions";

export const Order = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState({});
  const [timeZoneId, setTimeZoneId] = useState("America/Los_Angeles");
  const productsData = groupByCategory(orderData?.categories);
  const [isItemsOpen, setIsItemsOpen] = useState(false);
  const getParams = () => {
    let parsedParams = (url) => {
      return url.split("/");
    };

    const params = parsedParams(window.location.pathname);
    const businessId = params[1];
    const orderId = params[2];
    return {
      businessId,
      orderId
    }
  }

  useEffect(() => {
    const { orderId } = getParams();

    if (!orderId) {
      navigate("/404");
    }

    const fetchData = async () => {
      try {
        const response = await AuthService.getOrderData(orderId);
        if (response?.data?.data?.metadata?.business) {
          document.title = `Orders co | ${response?.data?.data?.metadata?.business?.companyName}`;
        } else {
          document.title = `Orders co`;
        }
        if (response) {
          setOrderData(response.data.data || {});
          setTimeZoneId(
            response?.data?.data?.metadata?.business?.timezone?.timeZoneId ||
            response?.data?.data?.metadata?.business?.timezone ||
            "America/Los_Angeles"
          );
        }
      } catch (e) {
        document.title = `Orders co`;
        setOrderData({});
        setTimeZoneId("America/Los_Angeles");
      }
    };
    fetchData().then();
  }, []);

  const handlePay = () => {
    const { businessId, orderId } = getParams();
    window.open(
      `${process.env.REACT_APP_PAYMENT_URL}?bId=${businessId}&oId=${orderId}`,
      "_blank"
    );
  };

  return (
    <>
      {orderData && Object.keys(orderData)?.length > 0 && (
        <div id="receipt">
          <OrderHeader orderData={orderData} timeZoneId={timeZoneId} />
          {orderData?.deliveryInfo &&
            (orderData?.orderType === ORDER_TYPE_KEYS.delivery ||
              orderData?.orderType === ORDER_TYPE_KEYS.catering_delivery ||
              orderData?.orderType === ORDER_TYPE_KEYS.phone_order_delivery)
              ? (
                <OrderDeliveryInfo
                  deliveryInfo={orderData?.deliveryInfo}
                  customerInfo={orderData?.customerInfo}
                  refCode={orderData?.metadata?.deliveryDetails?.refCode}
                />
              ) : (<></>)}
          {(orderData?.customerInfo?.firstName
            || orderData?.customerInfo?.phoneNumber) &&
          (orderData?.orderType !== ORDER_TYPE_KEYS.delivery &&
            orderData?.orderType !== ORDER_TYPE_KEYS.catering_delivery &&
            orderData?.orderType !== ORDER_TYPE_KEYS.phone_order_delivery)
            ? (
              <OrderPickUpInfo
                customerInfo={orderData?.customerInfo}
                refCode={orderData?.metadata?.deliveryDetails?.refCode}
              />
            ) : (<></>)}

          {orderData?.specialInstructions && (
            <OrderNote instructions={orderData?.specialInstructions}/>
          )}
          <div className="accordion">
            <div
              className="accordion-header"
              aria-expanded={isItemsOpen}
              onClick={() => setIsItemsOpen(!isItemsOpen)}
            >
              <p className="items">Items: (click to expand/collapse)</p>
              <span>{isItemsOpen ? "-" : "+"}</span>
            </div>
            <div className={`items-container ${isItemsOpen ? "open" : ""}`}>
              {isItemsOpen && (
                <table id="table">
                  <tbody>
                  {Object.keys(productsData)?.length > 0 ? (
                    Object.keys(productsData)?.map((keys, index) => {
                      return (
                        <>
                          <Fragment key={keys}>
                            {productsData[keys]?.categoryName && (
                              <>
                                {index !== 0 && <div className="separator" />}
                                <tr>
                                  <td
                                    style={{ fontWeight: 600 }}
                                    className="categoryName product-name-longer"
                                  >
                                    {productsData[keys].categoryName.toUpperCase()}
                                  </td>
                                </tr>
                              </>
                            )}
                          </Fragment>
                          {productsData[keys]?.items?.map((products) =>
                            products?.items?.map((item) => {
                              return (
                                <Fragment key={item?.itemId}>
                                  <OrderProduct product={item}/>
                                </Fragment>
                              )
                            })
                          )}
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <hr className="line-header"/>
          <table id="table">
            <tbody>
              <OrderCharges orderData={orderData}/>
            </tbody>
          </table>
          {orderData?.metadata?.transactions?.length > 0 ? (
            <OrderTransactions orderData={orderData} />
          ) : (
            <hr className="line-header" />
          )}
          {orderData?.metadata?.transactions?.length > 0 ? (
            <RefundTransactions orderData={orderData} />
          ) : (
            <></>
          )}
          <OrderFooter customerInfo={orderData?.customerInfo}/>
          {orderData?.charges?.balanceDue > 0 && (
            <>
              <hr className="line-header"/>
              <button className="pay" onClick={handlePay}>PAY</button>
            </>
          )}
        </div>
      )}
    </>
  );
};
