import { Fragment, memo } from "react";
import { getDiscountLabel } from "src/utils";
import { valueFormat } from "src/helper/valueFormat";

const styles = {
  row: { marginBottom: 0 },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  productNameContainer: {
    display: "flex",
    alignItems: "center",
  },
  count: { width: "30px", margin: 0 },
  discountText: { marginLeft: "28px", fontWeight: 500 },
  discountPrice: { fontWeight: 500, textAlign: "right", margin: 0 },
};

const OrderProduct = ({ product }) => {
  const {
    count,
    name,
    discount,
    price,
    couponCode,
    calculatedPrice,
    discountDetails,
    modifierGroup,
  } = product || {};

  const mainPrice = valueFormat(`$${Number(price || 0).toFixed(2)}`);
  const discountPrice = valueFormat(`$${Number(calculatedPrice || 0).toFixed(2)}`);

  return (
    <>
      <tr style={styles.row}>
        <div style={styles.flexContainer}>
          <div
            style={styles.productNameContainer}
            className="product-name product-name-longer"
          >
            <p style={styles.count}>
              {`${count}x `}
            </p>
            <span className="uppercase product-name-longer">{valueFormat(name?.en)}</span>
          </div>
          <p
            className="product-name product-longer-price uppercase"
            style={{
              textDecoration: discount ? "line-through" : "none",
            }}
          >
            {mainPrice}
          </p>
        </div>
      </tr>
      <tr>
        {(couponCode || discount > 0) && (
          <>
            <p
              className="product-name product-name-longer"
              style={styles.discountText}
            >
              Discount {getDiscountLabel(discountDetails)}
            </p>
            <p
              className="product-name product-longer-price"
              style={styles.discountPrice}
            >
              {discountPrice}
            </p>
          </>
        )}
      </tr>
      {modifierGroup &&
        modifierGroup.length > 0 &&
        modifierGroup.map((mod) => (
          <Fragment key={mod?._id}>
            <tr>
              <td className="modifier-main-name">
                {`${mod?.name?.en?.toUpperCase()}`}
              </td>
            </tr>
            {mod?.modifierOptions &&
              mod.modifierOptions.length > 0 &&
              mod.modifierOptions.map((item) => (
                <tr key={item?._id}>
                  <td className="modifier-name" style={{ paddingLeft: '16px' }}>
                    <div>
                      {item?.count ? <b>{`${item?.count}x `}</b> : null}
                      <p>{`${item?.name?.en}`}</p>
                      {item?.freeCount ? <b>({item?.freeCount} free)</b> : null}
                    </div>
                  </td>
                  <td className="modifier-name">
                    {item?.isFree
                      ? "-"
                      : valueFormat(`$${Number(item?.totalPrice || 0).toFixed(2)}`)}
                  </td>
                </tr>
              ))}
          </Fragment>
        ))}
    </>
  );
};

export default memo(OrderProduct);
