import { valueFormat } from "src/helper/valueFormat";

export const formatTimeForReceipt = (dateString) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Manually extracting the components from the date string
  const year = dateString.substring(0, 4);
  const monthIndex = parseInt(dateString.substring(5, 7), 10) - 1;
  const day = dateString.substring(8, 10);
  let hours = parseInt(dateString.substring(11, 13), 10);
  const minutes = dateString.substring(14, 16);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  const strTime = `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
  const month = months[monthIndex];

  return `${strTime} - ${month} ${parseInt(day, 10)}, ${year}`;
};

export function formatDateByTimeZone(isoDateString, timeZone) {
  // Parse the input string into a Date object (interpreted as UTC by default)
  const date = new Date(isoDateString);

  // If the date is invalid, handle gracefully
  if (isNaN(date?.getTime())) {
    return "";
  }

  // Use Intl.DateTimeFormat to format in the given time zone
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // Get the formatted parts (month/day/year) separately
  const parts = formatter.formatToParts(date);
  let month, day, year;
  parts.forEach((part) => {
    if (part.type === "month") month = part.value;
    if (part.type === "day")   day   = part.value;
    if (part.type === "year")  year  = part.value;
  });

  // Return the final string in "MM.DD.YYYY" format
  return `${month}.${day}.${year}`;
}


export function formatDateWithTimeZone(dateString) {
  if (!dateString) return null;

  const date = new Date(dateString);

  // Format the date as MM.DD.YYYY
  const dateStringPart = date
    .toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, '.');

  // Format the time as HH:MM AM/PM (with a leading zero for the hour)
  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  // Return the formatted string with two spaces between the date and time parts
  return `${dateStringPart}  ${timeString}`;
}


export const ORDER_TYPE_LABEL = {
  in_store: 'In Store',
  takeout: 'Takeout',
  phone_order_delivery: 'Phone Order/Delivery',
  phone_order_pickup: 'Phone Order/Pick Up',
  delivery: 'Delivery',
  pickup: 'Pickup',
  dinein: 'Dine-in',
  dine_in: 'Dine-in',
  take_out: 'Takeout',
  catering_pickup: 'Catering/Pickup',
  catering_delivery: 'Catering/Delivery'
};

const cleanAndUppercase = (str) => {
  return str?.replace(/[_/]/g, ' ')?.toUpperCase();
};

export const defaultProvider = 'POS';

export const ORDER_TYPE_KEYS = {
  in_store: 'in_store',
  takeout: 'takeout',
  phone_order_delivery: 'phone_order_delivery',
  phone_order_pickup: 'phone_order_pickup',
  pickup: 'pickup',
  dinein: 'dinein',
  dine_in: 'dine_in',
  take_out: 'take_out',
  catering_pickup: 'catering_pickup',
  catering_delivery: 'catering_delivery',
  delivery: 'delivery',
};


export const ORDER_TYPE_KEYS_DELIVERY = ['catering_delivery', 'phone_order_delivery', 'delivery'];

export const DISCOUNT_TYPE_LABEL = {
  fixedAmount: 'fixedAmount',
  percentage: 'percentage',
};


export function getOrderTypeLabel(key) {
  return ORDER_TYPE_LABEL[key] || cleanAndUppercase(key);
}

export const STATUS_NAME = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
}
export const TRANSACTION_TYPE = {
  refund: 'Refund',
  purchase: 'Purchase',
}


export function getDiscountLabel(discount) {
  const label = discount?.couponName || discount?.couponTitle || discount?.discountCode;
  const discountValue = valueFormat(discount?.discountValue || 0).toFixed(2);

  if (!discount) return "";

  const isLabelValid = label && label.toLowerCase() !== "discount";

  if (DISCOUNT_TYPE_LABEL.fixedAmount === discount.discountType) {
    return isLabelValid
      ? `(${label}) $${discountValue} off`
      : `$${discountValue} off`;
  }

  if (DISCOUNT_TYPE_LABEL.percentage === discount.discountType) {
    return isLabelValid
      ? `(${label}) ${discount?.discountValue || 0}% off`
      : `${discount?.discountValue || 0}% off`;
  }

  return "";
}

export const getCurrentTransactions = (item) => {
  return TRANSACTION_TYPE[item];
}

export const filteredData = (transactions = []) => {
  return transactions.filter((item) => item?.status?.name !== STATUS_NAME.PENDING &&
    item?.transactionType?.toLowerCase() === TRANSACTION_TYPE.purchase?.toLowerCase())
};

const parseJson = (emvTagData) => {
  if (typeof emvTagData === 'string') {
    try {
      return JSON.parse(emvTagData);
    } catch (error) {
      return null;
    }
  }
  return null;
};


const formatKey = (key) => {
  if (typeof key !== 'string') return key;
  return key.replace(/\s+/g, '_').toLowerCase();
};

const formatAllKeys = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => formatAllKeys(item));
  } else if (data !== null && typeof data === 'object') {
    const formattedObj = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const formattedKey = formatKey(key);
        formattedObj[formattedKey] = formatAllKeys(data[key]);
      }
    }
    return formattedObj;
  }
  return data;
};

export const combineParseAndFormat = (emvTagData) => {
  const parsedData = parseJson(emvTagData);
  if (!parsedData) {
    return null;
  }
  return formatAllKeys(parsedData);
};

export const TRANSACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}

export function findFailedRefunds(transactions) {
  if (transactions.length === 0) return [];
  return transactions.filter(tx =>
    tx.transactionType?.toLowerCase() === TRANSACTION_TYPE.refund.toLowerCase() &&
    tx.status &&
    tx.status.name === TRANSACTION_STATUS.FAIL
  );
}

export function findSuccessRefunds(transactions) {
  if (transactions.length === 0) return [];
  return transactions.filter(tx =>
      tx.transactionType?.toLowerCase() === TRANSACTION_TYPE.refund.toLowerCase() &&
      tx.status &&
      tx.status.name === TRANSACTION_STATUS.SUCCESS
  );
}

export function filterRefundsTransaction(transactions) {
  if (transactions.length === 0) return [];
  return transactions.filter(tx =>
    tx.transactionType?.toLowerCase() === TRANSACTION_TYPE.refund.toLowerCase() &&
    tx?.status?.name !== STATUS_NAME.PENDING
  );
}


export function formatDisplayNumber(number) {
  const num = Number(number);
  if (isNaN(num) || num < 0) {
    return '000';
  }
  return String(number?.replace('#', ''))?.padStart(3, '0')
}

export function getFirstLine(address1, address2) {
  if (!address1) return "";

  return address2 ? `${address1} ${address2},` : `${address1},`;
}

export function getSecondLine(city, state, zipCode) {
  const cityPart = city ? `${city}, ` : "";
  const stateZip = [state, zipCode].filter(Boolean).join(" ");

  return (cityPart + stateZip).trim();
}