import React, { memo } from 'react';
import {
  findFailedRefunds,
  findSuccessRefunds,
  formatDisplayNumber,
  getFirstLine,
  getSecondLine,
  getOrderTypeLabel,
  formatDateWithTimeZone,
  ORDER_TYPE_LABEL,
  defaultProvider,
} from "src/utils";

const RefundMessage = ({ failedRefunds = [], successRefunds = [] }) => {
  if (failedRefunds.length > 0 && successRefunds.length === 0) {
    return <p className="displayRefund">Refund Declined</p>;
  }
  if (successRefunds.length > 0) {
    return <p className="displayRefund">Refund</p>;
  }
  return null;
};

const ProviderDeliveryOrder = ({ provider }) => {
  return (
    <>
      <p className="orderType uppercase">
        {`Delivery By ${provider}`}
      </p>
      <hr className="line-header" />
    </>
  )
}

const OrderInfo = ({ createdAt , number, timeZoneId, orderType }) => {
  return (
    <>
      <p className="order-number">
        {createdAt ? (
          <>
            Ordered: {formatDateWithTimeZone(createdAt)} <br />
            {`Order Number #${number}`}
          </>
        ) : (
          <>{`Order Number #${number}`}</>
        )}
      </p>
      {orderType && (
        <>
          <hr className="line-header-long"/>
          <p className="orderType">
            {getOrderTypeLabel(orderType)}
          </p>
        </>
      )}
      <hr className="line-header-long"/>
    </>
  )
}

const BusinessInfo = ({ business }) => {
  if (!business) return null;

  const { title, companyName, image = null, addressInfo, phone = null, supportPhone = null } = business;

  return (
    <>
      {image?.url && (
        <div className="business-logo">
          <img src={image?.url} alt="business logo" />
        </div>
      )}
      <p className="companyName">{title || companyName}</p>
      {addressInfo && (
        <p className="address_info">
          {getFirstLine(addressInfo?.address1, addressInfo?.address2)} <br />
          {getSecondLine(addressInfo?.city, addressInfo?.state, addressInfo?.zipCode)}
        </p>
      )}
      {phone && (
        <p className="phone-number">{supportPhone || phone}</p>
      )}
      <hr className="line-header" />
    </>
  );
};

const CashierInfo = ({ employeeInfo = null, displayNumber, register = null }) => {
  return (
    <>
      {register && (
        <div className="cashierInfo">
          <p className="uppercase">Register:&nbsp;&nbsp;&nbsp;
            <span>{register}</span></p>
        </div>
      )}
      {employeeInfo && (
        <div className="cashierInfo">
          <p className="uppercase">Cashier:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{employeeInfo?.firstName}</span> <span>{employeeInfo?.lastName || ''}</span></p>
        </div>
      )}
      {displayNumber && (
        <div className="cashierInfo">
          <p className="uppercase">Check #:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{formatDisplayNumber(displayNumber)}</span></p>
        </div>
      )}
    </>
  )
}

const OrderHeader = ({ orderData, timeZoneId }) => {
  if (!orderData) return null;

  const {
    displayNumber,
    metadata,
    type = null,
    provider = null,
    employeeInfo = null,
    register = null
  } = orderData;
  const business = metadata?.business;
  const transactions = metadata?.transactions || [];
  const failedRefunds = findFailedRefunds(transactions);
  const successRefunds = findSuccessRefunds(transactions);

  return (
    <div id="receiptHeader">
      <div id="receiptHeaderInfo">
        {displayNumber && (
          <p className="displayNumber">{formatDisplayNumber(displayNumber)}</p>
        )}
        <RefundMessage failedRefunds={failedRefunds} successRefunds={successRefunds} />
        <BusinessInfo business={business} />
        <OrderInfo
          createdAt={orderData?.placedAt}
          number={orderData?.orderNumber}
          timeZoneId={timeZoneId}
          orderType={orderData?.orderType}
        />
        {type === ORDER_TYPE_LABEL.delivery && provider !== defaultProvider ? (
          <ProviderDeliveryOrder provider={provider} />
        ) : null}
        <CashierInfo
          employeeInfo={employeeInfo}
          displayNumber={displayNumber}
          register={orderData?.metadata?.deviceInfo?.title}
        />
        <hr className="line-header" />
      </div>
    </div>
  );
};

export default memo(OrderHeader);
