import {memo} from "react";


const OrderFooter = ({ customerInfo }) => {
  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      {customerInfo?.firstName ? (
       <div className="uppercase">
         <p className="thankYou-text">
           {`Thank you for your order, ${customerInfo?.firstName} ${customerInfo?.lastName?.charAt(0) || ''}!`}
         </p>
         <p className="thankYou-text-second">It was a pleasure serving you.</p>
         <p className="thankYou-text-second">Have a nice day!</p>
       </div>
      ) : (
        <div className="uppercase">
          <p className="thankYou-text">
            {`Thank you for your order!`}
          </p>
          <p className="thankYou-text-second">It was a pleasure serving you.</p>
          <p className="thankYou-text-second">Have a nice day!</p>
        </div>
      )}
    </div>
  )
}

export default memo(OrderFooter)